import { createStore } from 'vuex'
import axios from 'axios'
import {
  // isLocal
  isLocal,
  isBestGoldenOfferCom,
  isBodyMagicSnapPro,
  isUBodyRu,
  isStepVibeFitCom,
  isHypeColorQubeCom,
  isStayFitUBodyPro,
  isUstretchFitCom,
  isUstretchSetCom,
  isFroliCartFlashCom,
  isFileDownloadZoneCom,
  isInstantDownloadHubCom,
  isTotalFileAccessCom,
  isDowlndWatchCom,
  isWatchlyFileCom,
  isWatchDwlndCom,
  isFilterHubFileCom,
  isFileMobilesOrg,
  isEasyFileGrabCom,
  isMobileFileProCom,
  isFlickTapDownloadCom,
  isFiltersJoyCraftCom,
  isSnapTapCanvasCom,
  isSelfStretchRu,
  isSmartDeepRu
} from '@/utils/stand'

// import the auto exporter
import modules from './modules'

const getConfigUrl = () => {
  switch (true) {
    case isLocal():
      return 'https://config.artdeepfilters.com/api/v1/config?key=body.magicsnap.pro'
    // return 'https://config.artdeepfilters.com/api/v1/config?key=frolicartflash.com'
    case isBestGoldenOfferCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=bestgoldenoffer.com'
    case isStayFitUBodyPro():
      return 'https://config.artdeepfilters.com/api/v1/config?key=stayfitubody.pro'
    case isUBodyRu():
      return 'https://config.pwhelp.ru/api/v1/config?key=u-body.ru'
    case isStepVibeFitCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=stepvibefit.com'
    case isHypeColorQubeCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=hypecolorqube.com'
    case isBodyMagicSnapPro():
      return 'https://config.artdeepfilters.com/api/v1/config?key=body.magicsnap.pro'
    case isUstretchFitCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=ustretchfit.com'
    case isUstretchSetCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=ustretchset.com'
    case isFroliCartFlashCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=frolicartflash.com'
    case isFileDownloadZoneCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=filedownloadzone.com'
    case isInstantDownloadHubCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=instantdownloadhub.com'
    case isTotalFileAccessCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=totalfileaccess.com'
    case isDowlndWatchCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=dowlndwatch.com'
    case isWatchlyFileCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=watchlyfile.com'
    case isWatchDwlndCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=watchdwlnd.com'
    case isFilterHubFileCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=filterhubfile.com'
    case isFileMobilesOrg():
      return 'https://config.artdeepfilters.com/api/v1/config?key=filemobiles.org'
    case isEasyFileGrabCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=easyfilegrab.com'
    case isMobileFileProCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=mobilefilepro.com'
    case isFlickTapDownloadCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=flicktapdownload.com'
    case isFiltersJoyCraftCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=filtersjoycraft.com'
    case isSnapTapCanvasCom():
      return 'https://config.artdeepfilters.com/api/v1/config?key=snaptapcanvas.com'
    case isSelfStretchRu():
      return 'https://config.pwhelp.ru/api/v1/config?key=selfstretch.ru'
    case isSmartDeepRu():
      return 'https://config.pwhelp.ru/api/v1/config?key=smartdeep.ru'
  }
}

export const config = axios.create({
  baseURL: getConfigUrl()
})

const store = createStore({
  modules
})

export function useStore() {
  return store
}

export default store
