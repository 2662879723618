export const isLocal = () => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
  return LOCAL_DOMAINS.includes(window.location.hostname)
}

export const isBestGoldenOfferCom = () => {
  const BEST_GOLDEN_OFFER_COM = ['bestgoldenoffer.com']
  return BEST_GOLDEN_OFFER_COM.includes(window.location.hostname)
}

export const isStayFitUBodyPro = () => {
  const STAY_FIT_U_BODY_PRO = ['stayfitubody.pro']
  return STAY_FIT_U_BODY_PRO.includes(window.location.hostname)
}

export const isUBodyRu = () => {
  const U_BODY_RU = ['u-body.ru']
  return U_BODY_RU.includes(window.location.hostname)
}

export const isStepVibeFitCom = () => {
  const STEP_VIBE_FIT_COM = ['stepvibefit.com']
  return STEP_VIBE_FIT_COM.includes(window.location.hostname)
}

export const isHypeColorQubeCom = () => {
  const HYPE_COLOR_QUBE_COM = ['hypecolorqube.com']
  return HYPE_COLOR_QUBE_COM.includes(window.location.hostname)
}

export const isBodyMagicSnapPro = () => {
  const BODY_MAGIC_SNAP_PRO = ['body.magicsnap.pro']
  return BODY_MAGIC_SNAP_PRO.includes(window.location.hostname)
}

export const isUstretchFitCom = () => {
  const USTRETCH_FIT_COM = ['ustretchfit.com']
  return USTRETCH_FIT_COM.includes(window.location.hostname)
}

export const isUstretchSetCom = () => {
  const USTRETCH_SET_COM = ['ustretchset.com']
  return USTRETCH_SET_COM.includes(window.location.hostname)
}

export const isFroliCartFlashCom = () => {
  const FROLI_CART_FLASH_COM = ['frolicartflash.com']
  return FROLI_CART_FLASH_COM.includes(window.location.hostname)
}

export const isFileDownloadZoneCom = () => {
  const FILE_DOWNLOAD_ZONE_COM = ['filedownloadzone.com']
  return FILE_DOWNLOAD_ZONE_COM.includes(window.location.hostname)
}

export const isInstantDownloadHubCom = () => {
  const INSTANT_DOWNLOAD_HUB_COM = ['instantdownloadhub.com']
  return INSTANT_DOWNLOAD_HUB_COM.includes(window.location.hostname)
}

export const isTotalFileAccessCom = () => {
  const TOTAL_FILE_ACCESS_COM = ['totalfileaccess.com']
  return TOTAL_FILE_ACCESS_COM.includes(window.location.hostname)
}

export const isDowlndWatchCom = () => {
  const DOWLND_WATCH_COM = ['dowlndwatch.com']
  return DOWLND_WATCH_COM.includes(window.location.hostname)
}

export const isWatchlyFileCom = () => {
  const WATCHLY_FILE_COM = ['watchlyfile.com']
  return WATCHLY_FILE_COM.includes(window.location.hostname)
}

export const isWatchDwlndCom = () => {
  const WATCH_DWLND_COM = ['watchdwlnd.com']
  return WATCH_DWLND_COM.includes(window.location.hostname)
}

export const isFilterHubFileCom = () => {
  const FILTER_HUB_FILE_COM = ['filterhubfile.com']
  return FILTER_HUB_FILE_COM.includes(window.location.hostname)
}

export const isFileMobilesOrg = () => {
  const FILE_MOBILES_ORG = ['filemobiles.org']
  return FILE_MOBILES_ORG.includes(window.location.hostname)
}

export const isEasyFileGrabCom = () => {
  const EASY_FILE_GRAB_COM = ['easyfilegrab.com']
  return EASY_FILE_GRAB_COM.includes(window.location.hostname)
}

export const isMobileFileProCom = () => {
  const MOBILE_FILE_PRO_COM = ['mobilefilepro.com']
  return MOBILE_FILE_PRO_COM.includes(window.location.hostname)
}

export const isFlickTapDownloadCom = () => {
  const FLICK_TAP_DOWNLOAD_COM = ['flicktapdownload.com']
  return FLICK_TAP_DOWNLOAD_COM.includes(window.location.hostname)
}

export const isFiltersJoyCraftCom = () => {
  const FILTERS_JOY_CRAFT_COM = ['filtersjoycraft.com']
  return FILTERS_JOY_CRAFT_COM.includes(window.location.hostname)
}

export const isSnapTapCanvasCom = () => {
  const SNAP_TAP_CANVAS_COM = ['snaptapcanvas.com']
  return SNAP_TAP_CANVAS_COM.includes(window.location.hostname)
}

export const isSelfStretchRu = () => {
  const SELF_STRETCH_RU = ['selfstretch.ru']
  return SELF_STRETCH_RU.includes(window.location.hostname)
}

export const isSmartDeepRu = () => {
  const SMART_DEEP_RU = ['smartdeep.ru']
  return SMART_DEEP_RU.includes(window.location.hostname)
}
